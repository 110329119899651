<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedData()) {
      next();
    } else {
      this.setIgnoreAction(() => this.$router.replace(to));
    }
  },
  data() {
    return {
      showUnsavedDialog: false,
      ignoreUnsavedChanges: false,
      unsavedDialogText: "",
      ignoreAction: () => {},
    };
  },
  computed: {
    ...mapGetters("autosave", ["getStatus"]),
    unsavedText() {
      const pd = this.unsavedPatientData();
      const pc = this.unsavedPatientCard();
      const pi = this.unsavedPatientInfo();
      if (pd && pc) {
        return "Wprowadzone zmiany nie zostały zapisane. Czy na pewno chcesz opuścić porzucić zmiany?";
      } else if (pd) {
        return "Wprowadzone zmiany nie zostały zapisane. Czy na pewno chcesz opuścić edycję danych pacjenta?";
      } else if (pc) {
        return "Wprowadzone zmiany nie zostały zapisane. Czy na pewno chcesz opuścić edycję karty pacjenta?";
      } else if (pi) {
        return "Wprowadzone zmiany nie zostały zapisane. Czy na pewno chcesz opuścić edycję 'Informacje i zgody'?";
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("autosave", ["notModified"]),
    setIgnoreAction(action) {
      this.ignoreAction = () => {
        this.ignoreUnsavedChanges = true;
        if (action) {
          if (this.unsavedPatientData()) {
            this.notModified("patientData");
          }
          if (this.unsavedPatientCard()) {
            this.notModified("patientCard");
          }
          if (this.unsavedPatientInfo()) {
            this.notModified("patientInfo");
          }
          action();
        }
        this.ignoreUnsavedChanges = false;
      };
      this.unsavedDialogText = this.unsavedText;
      this.showUnsavedDialog = true;
    },
    unsavedData() {
      return (
        !this.ignoreUnsavedChanges &&
        (this.unsavedPatientData() ||
          this.unsavedPatientCard() ||
          this.unsavedPatientInfo())
      );
    },
    unsavedPatientData() {
      return [1, 2].includes(this.getStatus("patientData"));
    },
    unsavedPatientCard() {
      return [1, 2].includes(this.getStatus("patientCard"));
    },
    unsavedPatientInfo() {
      return [1, 2].includes(this.getStatus("patientInfo"));
    },
    handleTabIgnoreChanges(tabName) {
      const index = _.findIndex(this.tabs, { name: tabName });
      if (index !== -1) {
        this.activeTab = index;
      }
    },
    handleBlockedTab(tabName) {
      this.setIgnoreAction(() => this.handleTabIgnoreChanges(tabName));
    },
  },
};
</script>